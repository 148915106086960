// SPECIFIC FOR TEACHER MODULE
export enum Overview {
	STUDENTS = "Students",
	TARGETS = "Targets",
	EXAMS = "Exams",
}

export enum TimeFrame {
	ALL = "all",
	YEAR = "year",
	MONTH = "month",
	WEEK = "week",
}

// COPIES FROM STUDENT PORTAL
export enum Languages {
	// GRIEK = "el",
	CATALAN = "ca",
	DUTCH = "nl",
	ENGLISH_UK = "uk",
	ENGLISH_US = "us",
	FINNISH = "fi",
	FRENCH = "fr",
	GERMAN = "de",
	// HINDI = "hi",
	ITALIAN = "it",
	// PORTUGUESE = "pt",
	SPANISH = "es",
}

export enum Movement {
	// INTERVALS
	ASCENDING_INTERVAL = "Ascending interval",
	DESCENDING_INTERVAL = "Descending interval",
	HARMONY_INTERVAL = "Harmony interval",

	// CHORDS, INVERSIONS, PROGRESSIONS
	ASCENDING = "Ascending",
	DESCENDING = "Descending",
	HARMONY = "Harmony",

	// HARMONIC CONTEXT
	ONE_OCTAVE = "OneOctave",
	OCTAVE_HIGHER = "OctaveHigher",
	OCTAVE_LOWER = "OctaveLower",

	// SCALES
	ALTERNATING_1 = "Alternating root every other note",
	ALTERNATING_2 = "Alternating root every 2 notes",
	ALTERNATING_3 = "Alternating root every 3 notes",
	ASCENDING_SLOW = "Slowly ascending",
	ASCENDING_FAST = "Quickly ascending",
	DESCENDING_SLOW = "Slowly descending",
	DESCENDING_FAST = "Quickly descending",
	RANDOM_EASY = "Random (Easy)",
	RANDOM = "Random",
	RANDOM_HARD = "Random (Hard)",

	// MELODIC_DICTATION
	THREE_NOTES = "Three notes",
	FOUR_NOTES = "Four notes",
	FIVE_NOTES = "Five notes",
	SIX_NOTES = "Six notes",
	SEVEN_NOTES = "Seven notes",

	// INVERSION
	ASCENDING_RANDOM_VOICING = "Ascending random voicing",
	DESCENDING_RANDOM_VOICING = "Descending random voicing",
	HARMONY_RANDOM_VOICING = "Harmony random voicing",

	// JAZZ PROGRESSIONS
	SLOW = "Slow",
	FAST = "Medium",
	MELODY = "Melody",
}

export enum Icons {
	EXERCISE = "exercise",
	HOME = "home",
	HOME_ACTIVE = "homeActive",
	STATISTICS = "statistics",
	STATISTICS_ACTIVE = "statisticsActive",
	SETTINGS = "settings",
	SETTINGS_ACTIVE = "settingsActive",
	ASSESSMENTS = "assessments",
	ASSESSMENTS_ACTIVE = "assessmentsActive",
	EXAM = "exam",
	STREAK = "streak",
	STREAK_ZERO = "streakZero",
	TIMER = "timer",
	GOLD = "gold",
	SILVER = "silver",
	BRONZE = "bronze",
	MONTHLY = "monthly",
	YEARLY = "yearly",
	FREE = "free",
	LIFETIME = "lifetime",
	UNLIMITED = "unlimited",
	TRACK = "track",
	SCORES = "scores",
	CUP = "cup",
	CUSTOMIZE = "customize",
	ADS = "ads",
	CONTRACT = "contract",
	LONGEST_STREAK = "longestStreak",
	ARROW_LEFT = "leftArrow",
	ARROW_RIGHT = "rightArrow",
	EYE_OPEN = "eyeOpen",
	EYE_CLOSED = "eyeClosed",
	PLAY = "play",
	PAUSE = "pause",
	CHECK_CORRECT = "checkCorrect",
	ERROR_404 = "error404",
	CHECK = "check",
	FAST_FORWARD = "fastForward",
	PRACTICE = "practice",
	SHORTCUT = "SHORTCUT",
	SAVE = "save",
	DYNAMIC = "dynamic",
	STATIC = "static",
	CHECK_PURPLE = "checkPurple",
	FLAT_ICON = "flatIcon",
	FLAT_ICON_DARK = "flatIconDark",
	SHARP_ICON = "sharpIcon",
	SHARP_ICON_DARK = "sharpIconDark",
	EAR = "ear",
	EXCLAMATION = "exclamation",
	QUESTION = "question",
	BOOK = "book",
	THEORY = "theory",
	THEORY_ACTIVE = "theoryActive",
	STOPWATCH = "stopwatch",
	REPEAT = "repeat",
	TASK = "task",
	TARGET = "target",
	CSV = "csv",
	XLS = "xls",
}

export enum Dynamics {
	STATIC = "Static",
	DYNAMIC = "Dynamic",
}

export enum MusicTheory {
	INTERVALS = "Intervals",
	SCALES = "Scales",
	CHORDS = "Chords",
	INVERSIONS = "Inversions",
	PROGRESSIONS = "Progressions",
}

export enum Modules {
	// INTERVALS
	INTERVAL_COMPARISON = "IntervalComparison",
	INTERVAL_RECOGNITION = "IntervalRecognition",
	HARMONIC_CONTEXT = "HarmonicContext",

	// SCALES
	SCALE_RECOGNITION = "ScaleRecognition",
	MELODIC_DICTATION = "MelodicDictation",

	// CHORDS
	CHORD_RECOGNITION = "ChordRecognition",
	TRIAD_INVERSIONS = "TriadInversions",
	// EXTENDED_CHORDS = "ExtendedChords",

	// PROGRESSIONS
	PROGRESSIONS_MAJOR = "MajorProgressions",
	PROGRESSIONS_MINOR = "MinorProgressions",
	PROGRESSIONS_JAZZ = "JazzProgressions",
}

export enum PlayItem {
	QUESTION,
	ANSWER,
	EXAMPLE,
}

export enum Activity {
	EXERCISE,
	PRACTICE,
	EXAM,
	SHORTCUT,
}

// DIFFICULTY
export enum DifficultyIntervalComparison {
	ONE_OCTAVE = "OneOctave",
}
export enum DifficultyIntervalRecognition {
	ONE_OCTAVE = "OneOctave",
	OCTAVE_HIGHER = "OctaveHigher",
	// COMBINED = "Combined",
}
export enum DifficultyHarmonicContext {
	IONIAN = "Ionian",
	DORIAN = "Dorian",
	PHRYGIAN = "Phrygian",
	LYDIAN = "Lydian",
	MIXOLYDIAN = "Mixolydian",
	AEOLIAN = "Aeolian",
	LOCRIAN = "Locrian",
	CHROMATIC = "Chromatic",
}
export enum DifficultyScaleRecognition {
	ALTERNATING = "AlternatingRoot",
	ASCENDING = "Ascending",
	DESCENDING = "Descending",
	MELODY = "Melody",
}
export enum DifficultyMelodicDictation {
	SHORT_ONE_OCTAVE = "ShortOneOctave",
	SHORT_TWO_OCTAVES = "ShortTwoOctaves",
	LONG_ONE_OCTAVE = "LongOneOctave",
	LONG_TWO_OCTAVES = "LongTwoOctaves",
}
export enum DifficultyTriadInversions {
	STANDARD_VOICING = "StandardVoicing",
	RANDOM_VOICING = "RandomVoicing",
}
export enum DifficultyChordRecognition {
	STANDARD_VOICING = "StandardVoicing",
	RANDOM_VOICING = "RandomVoicing",
}
export enum DifficultyProgressions {
	ROOT = "Root",
	FIRST_INVERSION = "1st",
	ROOT_AND_FIRST_INVERSION = "Root&1st",
	SECOND_INVERSION = "2nd",
	ROOT_AND_SECOND_INVERSION = "Root&2nd",
	ALL = "All",
}

export enum DifficultyProgressionsJazz {
	COMMON_PROGRESSIONS = "Common Progressions",
	SECONDARY_DOMINANT_MAJOR = "Secondary V (major)",
	// MODULATIONS_MINOR = "Minor Modulations",
	SECONDARY_DOMINANT_MINOR = "Secondary V (minor)",
	MODULATIONS_MAJOR = "Modulations (major)",
}

// MUSIC
export enum Scales {
	// 5 notes
	MAJOR_PENTATONIC = "Major pentatonic",
	MINOR_PENTATONIC = "Minor pentatonic",

	// 6 notes
	BLUES = "Blues",
	WHOLE_TONE = "Whole tone",

	// 7 notes
	// church modes
	IONIAN = "Major (Ionian)",
	DORIAN = "Dorian",
	PHRYGIAN = "Phrygian",
	LYDIAN = "Lydian",
	MIXOLYDIAN = "Mixolydian",
	AEOLIAN = "Minor (Aeolian)",
	LOCRIAN = "Locrian",

	// altered modes
	ALTERED_I = "Altered I",
	ALTERED_II = "Altered II (Melodic minor)",
	ALTERED_III = "Altered III",
	ALTERED_IV = "Altered IV",
	ALTERED_V = "Altered V",
	ALTERED_VI = "Altered VI",
	ALTERED_VII = "Altered VII",

	// harmonic major modes
	HARMONIC_MAJOR_I = "Harmonic Major I",
	HARMONIC_MAJOR_II = "Harmonic Major II",
	HARMONIC_MAJOR_III = "Harmonic Major III",
	HARMONIC_MAJOR_IV = "Harmonic Major IV",
	HARMONIC_MAJOR_V = "Harmonic Major V",
	HARMONIC_MAJOR_VI = "Harmonic Major VI",
	HARMONIC_MAJOR_VII = "Harmonic Major VII",

	// spanish phrygian modes
	SPANISH_PHRYGIAN_I = "Spanish Phrygian I",
	SPANISH_PHRYGIAN_II = "Spanish Phrygian II",
	SPANISH_PHRYGIAN_III = "Spanish Phrygian III",
	SPANISH_PHRYGIAN_IV = "Spanish Phrygian IV",
	SPANISH_PHRYGIAN_V = "Spanish Phrygian V",
	SPANISH_PHRYGIAN_VI = "Spanish Phrygian VI (Harmonic minor)",
	SPANISH_PHRYGIAN_VII = "Spanish Phrygian VII",

	MELODIC_MINOR = "Melodic minor",
	HARMONIC_MINOR = "Harmonic minor",

	// 8 notes
	OCTATONIC = "Diminished Octatonic",
	BEBOP_DOMINANT = "Bebop dominant",
	BEBOP_MAJOR = "Bebop major",
	BEBOP_MELODIC_MINOR = "Bebop melodic minor",
	BEBOP_HARMONIC_MINOR = "Bebop harmonic minor",

	// 9 notes
	COMPOSITE_BLUES = "Composite blues",

	// 11 notes
	ALTERED = "Altered",

	// 12 notes
	CHROMATIC = "Chromatic",
}

export enum Chords {
	MAJOR = "Major",
	DOMINANT = "Dominant",
	MAJOR_7TH = "Major 7th",

	MINOR = "Minor",
	MINOR_7TH = "Minor 7th",
	MINOR_MAJOR_7TH = "Min/Maj 7th",

	AUG = "Augmented",
	AUG_7TH = "Aug 7th",
	AUG_MAJOR_7TH = "Aug Major 7th",

	DIM = "Diminished",
	DIM_7TH = "Dim 7th",
	HALF_DIM_7TH = "Half-dim 7th",

	SUS2 = "Sus2",
	SUS4 = "Sus4",
}

export enum Inversions {
	MAJOR_ROOT = "Major Root Position",
	MAJOR_1ST_INVERSION = "Major 1st Inversion",
	MAJOR_2ND_INVERSION = "Major 2nd Inversion",
	MINOR_ROOT = "Minor Root Position",
	MINOR_1ST_INVERSION = "Minor 1st Inversion",
	MINOR_2ND_INVERSION = "Minor 2nd Inversion",
}

export enum InvPrefix {
	ROOT = "",
	FIRST_INVERSION = "1st",
	SECOND_INVERSION = "2nd",
}

export enum Degrees {
	I = "I",
	i = "i",
	bII = "bII",
	bii = "bii",
	II = "II",
	ii = "ii",
	iiDim = "ii°",
	bIII = "bIII",
	biii = "biii",
	III = "III",
	iii = "iii",
	IV = "IV",
	iv = "iv",
	bV = "bV",
	bv = "bv",
	V = "V",
	v = "v",
	bVI = "bVI",
	bvi = "bvi",
	VI = "VI",
	vi = "vi",
	bVII = "bVII",
	bvii = "bvii",
	VII = "VII",
	vii = "vii",
	viiDim = "vii°",
}

export enum DegreesFirstInversion {
	I = "1st I",
	i = "1st i",
	bII = "1st bII",
	bii = "1st bii",
	II = "1st II",
	ii = "1st ii",
	iiDim = "1st ii°",
	bIII = "1st bIII",
	biii = "1st biii",
	III = "1st III",
	iii = "1st iii",
	IV = "1st IV",
	iv = "1st iv",
	bV = "1st bV",
	bv = "1st bv",
	V = "1st V",
	v = "1st v",
	bVI = "1st bVI",
	bvi = "1st bvi",
	VI = "1st VI",
	vi = "1st vi",
	bVII = "1st bVII",
	bvii = "1st bvii",
	VII = "1st VII",
	vii = "1st vii",
	viiDim = "1st vii°",
}

export enum DegreesSecondInversion {
	I = "2nd I",
	i = "2nd i",
	bII = "2nd bII",
	bii = "2nd bii",
	II = "2nd II",
	ii = "2nd ii",
	iiDim = "2nd ii°",
	bIII = "2nd bIII",
	biii = "2nd biii",
	III = "2nd III",
	iii = "2nd iii",
	IV = "2nd IV",
	iv = "2nd iv",
	bV = "2nd bV",
	bv = "2nd bv",
	V = "2nd V",
	v = "2nd v",
	bVI = "2nd bVI",
	bvi = "2nd bvi",
	VI = "2nd VI",
	vi = "2nd vi",
	bVII = "2nd bVII",
	bvii = "2nd bvii",
	VII = "2nd VII",
	vii = "2nd vii",
	viiDim = "2nd vii°",
}

export enum DegreesJazz {
	Dim = "Dim",

	Imaj7 = "Imaj7",

	bII7 = "bII7",
	V7 = "V7",

	i7 = "i7",
	ii7b5 = "ii7b5",
	V7b9 = "V7b9",

	bIImaj7 = "bIImaj7",
	bII7overbII = "bII7/bII",
	ii7overbII = "ii7/bII",
	V7overbII = "V7/bII",

	IImaj7 = "IImaj7",
	bII7overII = "bII7/II",
	ii7overII = "ii7/II",
	V7overII = "V7/II",

	bIIImaj7 = "bIIImaj7",
	bII7overbIII = "bII7/bIII",
	ii7overbIII = "ii7/bIII",
	V7overbIII = "V7/bIII",

	bIII7 = "bIII7",

	IIImaj7 = "IIImaj7",
	bII7overIII = "bII7/III",
	ii7overIII = "ii7/III",
	V7overIII = "V7/III",

	IVmaj7 = "IVmaj7",
	bII7overIV = "bII7/IV",
	ii7overIV = "ii7/IV",
	V7overIV = "V7/IV",

	Vmaj7 = "Vmaj7",
	bII7overV = "bII7/V",
	ii7overV = "ii7/V",
	V7overV = "V7/V",

	bVImaj7 = "bVImaj7",
	bII7overbVI = "bII7/bVI",
	ii7overbVI = "ii7/bVI",
	V7overbVI = "V7/bVI",

	VImaj7 = "VImaj7",
	bII7overVI = "bII7/VI",
	ii7overVI = "ii7/VI",
	V7overVI = "V7/VI",

	bVIImaj7 = "bVIImaj7",
	bII7overbVII = "bII7/bVII",
	ii7overbVII = "ii7/bVII",
	V7overbVII = "V7/bVII",

	VIImaj7 = "VIImaj7",
	bII7overVII = "bII7/VII",
	ii7overVII = "ii7/VII",
	V7overVII = "V7/VII",

	ii7 = "ii7",
	bII7overii = "bII7/ii",
	ii7b5overii = "ii7b5/ii",
	V7b9overii = "V7b9/ii",

	iii7 = "iii7",
	bII7overiii = "bII7/iii",
	ii7b5overiii = "ii7b5/iii",
	V7b9overiii = "V7b9/iii",

	iv7 = "iv7",
	bII7overiv = "bII7/iv",
	ii7b5overiv = "ii7b5/iv",
	V7b9overiv = "V7b9/iv",

	v7 = "v7",
	bII7overv = "bII7/v",
	ii7b5overv = "ii7b5/v",
	V7b9overv = "V7b9/v",

	vi7 = "vi7",
	bII7overvi = "bII7/vi",
	ii7b5overvi = "ii7b5/vi",
	V7b9overvi = "V7b9/vi",

	SharpiDim = "#iDim",
	SharpiiDim = "#iiDim",
	SharpivDim = "#ivDim",
	SharpvDim = "#vDim",
	SharpviiDim = "#viiDim",
	biDim = "bIDim",
	biiDim = "biiDim",
	biiiDim = "biiiDim",
	bviDim = "bviDim",
	bviiDim = "bviiDim",
}

export enum Subscription {
	MONTHLY = "monthly",
	YEARLY = "yearly",
	LIFETIME = "lifetime",
	FREE = "free",
	STUDENT = "student",
}
