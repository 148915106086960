import React from "react";
import { Link, useLocation } from "react-router-dom";
import { LOGO } from "../data/constants/images";
import { useNavigate } from "react-router-dom";
import { useTeacher } from "../context/UserContext";
import { FirebaseManager } from "../services/firebase/FirebaseManager";
import { useTranslation } from "react-i18next";
import { useAlert } from "../context/AlertContext";
import * as Sentry from "@sentry/react";

export default function Header(): JSX.Element {
	const { teacher } = useTeacher();
	const navigate = useNavigate();

	const handleClick = (redirectPath: string) => {
		if (!teacher) window.location.href = "https://auriseartraining.com";
		else navigate(redirectPath);
	};

	return (
		<header className={teacher ? "" : "loginPage"}>
			<img id="logo" alt="logo" src={LOGO} onClick={() => handleClick("/")} />
			{teacher ? <LoggedInMenu /> : <LoggedOutMenu />}
		</header>
	);
}

function LoggedInMenu(): JSX.Element {
	const { setTeacher } = useTeacher();
	const { triggerAlert } = useAlert();
	const navigate = useNavigate();
	const { t } = useTranslation("Header");

	const handleLogout = async () => {
		try {
			await FirebaseManager.logout(setTeacher);
			navigate("/login");
		} catch (error) {
			Sentry.captureException(error);
			triggerAlert(t("logoutError"), "error");
		}
	};

	return (
		<nav>
			<Link to="/">{t("home")}</Link>
			<Link to="/manage_classes">{t("manageClasses")}</Link>
			<Link to="/settings">{t("settings")}</Link>
			<Link to="/login" onClick={handleLogout}>
				{t("logout")}
			</Link>
		</nav>
	);
}

function LoggedOutMenu(): JSX.Element {
	const { t } = useTranslation("Header");

	// const location = window.location.href;
	const location = useLocation().pathname;
	console.log(location);
	if (location == "/" || location.includes("login") || location.includes("Password"))
		return <h1>{t("teacherPortal")}</h1>;
	return (
		<nav className="">
			<span></span>
			<h1>{t("teacherPortal")}</h1>
			<Link to="/login">{t("login")}</Link>
			{/* <Link to="https://auriseartraining.com">{t("backToHome")}</Link> */}
		</nav>
	);
}
