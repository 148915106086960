import React, { useEffect } from "react";
import * as t from "../../../data/types";
import { useState } from "react";
import CsvUploader from "../../../components/UploadButton";
import { CSVImportUtil } from "../../../utils/csvImportUtil";
import { checkForUnsafeCharacters, isValidEmail } from "../../../utils/utils";
import { FirebaseManager } from "../../../services/firebase/FirebaseManager";
import { useTeacherLoggedIn } from "../../../context/UserContext";
import getClassesByTeacherAPI from "../../../services/api/getClassesByTeacherAPI";
import Loading from "../../../components/Loading";
import { useAlert } from "../../../context/AlertContext";
import { checkIfStudentIsUnique } from "./CreateNewStudent";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
				
export function ImportStudentsFromCSVFile({ classToEdit }: { classToEdit: t.Class | null }) {
	const [studentList, setStudentList] = useState<t.UserImport[]>([]);
	const { teacher, setClassData } = useTeacherLoggedIn();
	const [loading, setLoading] = useState<boolean>(false);

	const { triggerAlert } = useAlert();

	// those columns are allowed and required, they must exist in the csv file in order to import students
	let columns: string[] = ["FirstName", "LastName", "StudentNumber", "Email"];

	const validateColumnsCSVFile = (fileColumns: string[]) => {
		try {
			CSVImportUtil.validateColumns(fileColumns, columns);
		} catch (error: any) {
			Sentry.captureException(error);
			triggerAlert(error.message);
			return;
		}
	};

	const handleStudentCsvUpload = (content: string | any[], fileType: string) => {
		try {
			let _studentList;
			if (fileType === "csv") {
				const fileColumns = CSVImportUtil.getColumns(content as string);
				validateColumnsCSVFile(fileColumns);
				_studentList = CSVImportUtil.parseJsonFromCSV(content as string, columns);
			} else if (fileType === "excel") {
				// content is an array of objects
				const excelData = content as any[];
				if (excelData.length === 0) {
					triggerAlert("The Excel file is empty");
					return;
				}
				const firstRow = excelData[0];
				const fileColumns = Object.keys(firstRow);
				validateColumnsCSVFile(fileColumns);
				_studentList = excelData;
			}
			setStudentList(_studentList as unknown as t.UserImport[]);
		} catch (error: any) {
			Sentry.captureException(error);
			triggerAlert(error.message);
		}
	};
	/**
	 * This will be called when the user modifies the student list and wants to save it
	 * @param studentList
	 */
	function onSave(studentList: t.UserImport[]) {
		setStudentList(studentList);
	}

	async function startImport() {
		if (!validateBeforeImport()) return;

		setLoading(true);

		let successfullyImportedStudents = 0;
		let attemptedImport = 0;
		for (const student in studentList) {
			if (studentList[student].deselectedForImport) continue;
			attemptedImport++;
			const password = studentList[student].StudentNumber;
			try {
				await FirebaseManager.createNewStudent(
					studentList[student].Email,
					password,
					studentList[student].FirstName,
					studentList[student].LastName,
					studentList[student].StudentNumber,
					classToEdit!
				);
				successfullyImportedStudents++;
			} catch (error) {
				Sentry.captureException(error);
				triggerAlert("Error importing student: " + studentList[student].Email);
				console.error(error);
			}
		}

		await getClassesByTeacherAPI(teacher.id, setClassData);
		setStudentList([]);
		setLoading(false);
		triggerAlert(`${successfullyImportedStudents} of ${attemptedImport} Students imported successfully`, "success");
	}

	/**
	 * This will check if the students are unique
	 * This checks every student in the list with every student in the import and every existing student in the class
	 * @returns false if a student is not unique
	 */
	function validateBeforeImport() {
		const classCopy = JSON.parse(JSON.stringify(classToEdit));
		console.log("Validating students before import");
		console.log("ORIGINAL CLASS");
		console.log(classCopy);
		/**
		 * This function will be called on failure
		 * @param errorMessage
		 * @returns
		 */
		function failFunction(errorMessage: string | ((prevState: string) => string)) {
			triggerAlert(errorMessage as string);
			return false;
		}

		// validate every student and add them to the class
		for (const student in studentList) {
			if (studentList[student].deselectedForImport) continue;

			// validating email
			if (isValidEmail(studentList[student].Email) === false) {
				failFunction("Email is not valid: " + studentList[student].Email);
				return false;
			}

			// make sure all fields are filled
			if (
				!studentList[student].FirstName ||
				!studentList[student].LastName ||
				!studentList[student].StudentNumber ||
				!studentList[student].Email
			) {
				failFunction("Please fill in all fields");
				return false;
			}

			if (
				checkForUnsafeCharacters([
					studentList[student].FirstName,
					studentList[student].LastName,
					studentList[student].StudentNumber,
					studentList[student].Email,
				])
			) {
				failFunction(t("ErrorMessageUnsafeCharacters"));
				return false;
			}

			if (
				!checkIfStudentIsUnique(
					studentList[student].FirstName,
					studentList[student].LastName,
					studentList[student].StudentNumber,
					studentList[student].Email,
					[classCopy!],
					failFunction
				)
			) {
				return false;
			}
			classCopy!.Students.push(studentList[student]);
		}

		return true;
	}

	const generateExampleCSVFile = () => {
		const csvContent = columns.join(",") + "\n";
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "sample.csv");
		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	};

	const { t } = useTranslation("ManageClasses");

	return (
		<div>
			{loading && <Loading />}
			{studentList.length === 0 && (
				<p>
					{t("emptyStudentListMessage")}
					<br />
					<a onClick={generateExampleCSVFile}>{t("downloadLinkText")}</a>
				</p>
			)}
			{studentList.length > 0 && classToEdit ? (
				<>
					<ImportStudentList studentList={studentList} onSave={onSave} />
					<button onClick={startImport}>
						{t("importButtonLabel")} ({studentList.filter((student) => !student.deselectedForImport).length}
						)
					</button>
				</>
			) : (
				<CsvUploader callback={handleStudentCsvUpload}></CsvUploader>
			)}
		</div>
	);
}

/**
 * A list displaying all students that will be imported. The user can edit some basic information and deselect students.
 * TODO: generalize student list class, could be useful
 * @param param0
 * @returns
 */
function ImportStudentList({
	studentList,
	onSave,
}: {
	studentList: t.UserImport[];
	onSave: (students: t.UserImport[]) => void;
}) {
	const [editedStudentList, setEditedStudentList] = useState<t.UserImport[]>(studentList); // edited student list

	useEffect(() => {
		setEditedStudentList(studentList);
	}, [studentList]);

	/**
	 * Toggle the import status of a student
	 * @param student
	 */
	function toggleImportStatus(student: t.UserImport) {
		student.deselectedForImport = !student.deselectedForImport;
		setEditedStudentList([...editedStudentList]);
		handleSave(editedStudentList.indexOf(student));
	}

	/**
	 * Handle the change of the input field
	 * @param id
	 * @param field
	 * @param value
	 */
	function handleFieldChange(id: number, field: keyof t.UserImport, value: string) {
		const updatedStudents = editedStudentList.map((student, index) => {
			if (index === id)
				return {
					...student,
					[field]: value,
				};
			return student;
		});
		setEditedStudentList(updatedStudents);
		onSave(updatedStudents);
	}

	/**
	 * Save the edited data
	 * @param id
	 */
	function handleSave(id: number) {
		const updatedStudents = editedStudentList.map((student, index) => {
			if (index === id) {
				return {
					...student,
				};
			}
			return student;
		});
		setEditedStudentList(updatedStudents);
		onSave(updatedStudents);
	}

	const { t } = useTranslation("ManageClasses");

	return (
		<>
			<p>{t("checkListMessage")}</p>
			<p>{t("editInfoMessage")}</p>
			<br />
			<p>{t("passwordNotice")}</p>
			<br />
			<div className="overviewTable">
				<table>
					<thead className="theadCSS">
						<tr>
							<th>{t("nameHeader")}</th>
							<th>{t("studentIdHeader")}</th>
							<th>{t("emailHeader")}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{editedStudentList &&
							editedStudentList.map((student, id) => {
								return (
									<tr key={id}>
										<EditableStudentListRow
											student={student as t.User}
											id={id}
											onFieldChange={handleFieldChange}
											onSave={handleSave}
										/>
										<td
											className={!student.deselectedForImport ? "error link" : "link"}
											onClick={() => toggleImportStatus(student)}
										>
											{!student.deselectedForImport ? "Remove from import" : "Add to import"}
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
			<br />
			<br />
		</>
	);
}

interface EditableStudentListProps {
	student: t.User;
	id: number;
	onFieldChange: (id: number, field: keyof t.User, value: string) => void;
	onSave: (id: number) => void;
}

/**
 * The editable row of the student list
 * TODO: Generalize this component, use it in other places
 * @param param0
 * @returns
 */
const EditableStudentListRow: React.FC<EditableStudentListProps> = ({ student, id, onFieldChange, onSave }) => {
	const [editableFields, setEditableFields] = useState<{ [key: string]: boolean }>({
		FirstName: false,
		LastName: false,
		StudentNumber: false,
		Email: false,
	});

	const toggleEditableField = (field: keyof t.User) => {
		setEditableFields({
			...editableFields,
			[field]: !editableFields[field],
		});
	};

	const handleKeyDown = (event: React.KeyboardEvent, field: keyof t.User) => {
		if (event.key === "Enter") {
			toggleEditableField(field);
			onSave(id);
		}
	};

	function toggleNameFields() {
		toggleEditableField("FirstName");
		toggleEditableField("LastName");
	}

	function saveName(event: React.KeyboardEvent, field: keyof t.User) {
		if (event.key === "Enter") {
			toggleEditableField("FirstName");
			toggleEditableField("LastName");
			onSave(id);
		}
	}

	const lostFocus = (field: string) => {
		toggleEditableField(field as keyof t.User);
		onSave(id);
	};

	return (
		<>
			<td className="link">
				{/* TODO: IMPROVE EDITING OF FIRST & LAST NAME */}{" "}
				{editableFields.FirstName || editableFields.LastName ? (
					<div>
						<input
							type="text"
							style={{ display: "inline" }}
							value={student.FirstName}
							onChange={(e) => onFieldChange(id, "FirstName", e.target.value)}
							onKeyDown={(e) => saveName(e, "FirstName")}
							onBlur={() => lostFocus("FirstName")}
						/>{" "}
						<input
							style={{ display: "inline" }}
							type="text"
							value={student.LastName}
							onChange={(e) => onFieldChange(id, "LastName", e.target.value)}
							onKeyDown={(e) => saveName(e, "LastName")}
							onBlur={() => lostFocus("LastName")}
						/>
					</div>
				) : (
					<div>
						<span onClick={() => toggleNameFields()}>{student.FirstName}</span>{" "}
						<span onClick={() => toggleNameFields()}>{student.LastName}</span>
					</div>
				)}
			</td>
			<td className="link">
				{editableFields.StudentNumber ? (
					<input
						type="text"
						value={student.StudentNumber}
						onChange={(e) => onFieldChange(id, "StudentNumber", e.target.value)}
						onKeyDown={(e) => handleKeyDown(e, "StudentNumber")}
						onBlur={() => lostFocus("StudentNumber")}
					/>
				) : (
					<span onClick={() => toggleEditableField("StudentNumber")}>{student.StudentNumber}</span>
				)}
			</td>
			<td className="link">
				{editableFields.Email ? (
					<input
						type="text"
						value={student.Email}
						onChange={(e) => onFieldChange(id, "Email", e.target.value)}
						onKeyDown={(e) => handleKeyDown(e, "Email")}
						onBlur={() => lostFocus("Email")}
					/>
				) : (
					<span onClick={() => toggleEditableField("Email")}>{student.Email}</span>
				)}
			</td>
		</>
	);
};
