import React, { useEffect } from "react";
import Coupons from "./Coupons";
import { useTeacher } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import UsageStats from "./UsageStats";

export default function Dashboard(): JSX.Element {
	const { teacher } = useTeacher();

	const navigate = useNavigate();
	useEffect(() => {
		if (teacher?.Email !== "teacher1@school1.com") {
			navigate("/");
		}
	}, [teacher]);

	return (
		<main className="stats">
			<Coupons />
			<UsageStats />
		</main>
	);
}
