import * as t from "../../data/types";
import * as e from "../../data/enums";
import React, { useMemo, useState } from "react";
import useTableSort from "../../hooks/useTableSort";
import { parseDate } from "../../utils/dates";
import { CommonModal, ModalNavigation } from "../../components/CommonModal";
import { useModal } from "../../hooks/useModal";
import SortableTable from "../../components/SortableTable";
import ExamInfo from "./ExamInfo";
import { parseExamData } from "../../utils/utils";
import { useTeacherLoggedIn } from "../../context/UserContext";
import deleteExamAPI from "../../services/api/deleteExamAPI";
import getClassesByTeacherAPI from "../../services/api/getClassesByTeacherAPI";
import { handleError } from "../../utils/sharedUtils";
import { useAlert } from "../../context/AlertContext";
import CreateNewExam from "./CreateNewExam";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

export default function Exams({ selectedClass }: { selectedClass: t.Class | undefined }): JSX.Element {
	const [showCreateNew, setShowCreateNew] = useState<boolean>(false);
	const [selectedExam, setSelectedExam] = React.useState<t.Exam | undefined>(undefined);

	const { teacher, setClassData, setLoading } = useTeacherLoggedIn();
	const { triggerConfirm, triggerAlert } = useAlert();
	const { showModal, setShowModal, handleShowModal, selectedItem, setSelectedItem } = useModal<t.Exam>();
	const examData = useMemo(() => parseExamData(selectedClass), [selectedClass]);
	const { sortedData: sortedExams, handleSort, sortConfig } = useTableSort(examData);

	const now = new Date();
	const { t } = useTranslation("Overview");

	const renderAdditionalCell = (examObject: string[]) => {
		const examID = examObject[5];
		if (!selectedClass) return <></>;
		const exam = selectedClass.Exams?.find((exam) => exam.id === examID);

		function showModal() {
			handleShowModal(exam);
		}

		function editExam() {
			setShowCreateNew(true);
			setSelectedExam(exam);
		}

		async function deleteExamCheck() {
			triggerConfirm(t("confirmDelete"), async () => {
				await deleteExam();
			});
		}

		async function deleteExam() {
			if (!exam || !selectedClass) return;

			setLoading(true);
			try {
				exam.ClassID = selectedClass.id;
				await deleteExamAPI(exam);
				await getClassesByTeacherAPI(teacher.id, setClassData);
				triggerAlert(t("examDeleted1") + exam.Name + t("examDeleted2"), "success");
			} catch (error) {
				triggerAlert(handleError(error).userMessage, "error");
				Sentry.captureException(error);
			} finally {
				setSelectedExam(undefined);
				setLoading(false);
			}
		}

		if (exam && parseDate(exam?.StartTime) < now)
			return (
				<>
					<td className="alignRight link" onClick={showModal}>
						{t("examshowResults")}
					</td>
					<td className="alignRight link error" onClick={deleteExamCheck}>
						{t("examdelete")}{" "}
					</td>
				</>
			);
		else if (exam)
			return (
				<>
					<td className="alignRight link" onClick={editExam}>
						{t("examedit")}{" "}
					</td>
					<td className="alignRight link error" onClick={deleteExamCheck}>
						{t("examdelete")}{" "}
					</td>
				</>
			);
	};

	function showOnClick(examObject: string) {
		const exam = selectedClass?.Exams?.find((exam) => exam.id === examObject);
		const examStartTime = exam ? parseDate(exam.StartTime) : null;
		if (exam && examStartTime && examStartTime < now) handleShowModal(exam);
	}

	if (!selectedClass) return <></>;
	return (
		<>
			<div className="overviewTable">
				<SortableTable
					columns={[
						t("examdate"),
						t("examname"),
						t("examstartTime"),
						t("examfinished"),
						t("examavgGrade"),
						"",
					]}
					data={sortedExams}
					handleSort={handleSort}
					sortConfig={sortConfig}
					additionalCellRender={(examObject) => renderAdditionalCell(examObject) || <></>}
					rowOnClick={(examObject) => showOnClick(examObject)}
					putSpaceBeforeCapitals={false}
				/>
				<ExamModal
					exam={selectedItem}
					exams={selectedClass.Exams}
					setSelectedExam={setSelectedItem}
					selectedClass={selectedClass}
					showModal={showModal}
					setShowModal={setShowModal}
				/>
			</div>
			<CreateNewSection
				selectedClass={selectedClass}
				showCreateNew={showCreateNew}
				setShowCreateNew={setShowCreateNew}
				selectedExam={selectedExam}
				setSelectedExam={setSelectedExam}
			/>
		</>
	);
}

function ExamModal({
	exam,
	exams,
	setSelectedExam,
	selectedClass,
	showModal,
	setShowModal,
}: {
	exam: t.Exam | undefined;
	exams: t.Exam[] | undefined;
	setSelectedExam: React.Dispatch<React.SetStateAction<t.Exam | undefined>>;
	selectedClass: t.Class | undefined;
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
	if (!exam || !exams) return <></>;

	const examIndex = exams?.findIndex((s) => s.id === exam?.id);
	const navigateTarget = (direction: "left" | "right") => {
		const newIndex = direction === "left" ? examIndex - 1 : examIndex + 1;
		if (newIndex < 0 || newIndex > exams.length - 1) return;
		setSelectedExam(exams[newIndex]);
	};

	return (
		<CommonModal showModal={showModal} setShowModal={setShowModal}>
			<ModalNavigation
				currentIndex={examIndex}
				totalItems={exams.length}
				onNavigate={navigateTarget}
				navigationLabel={exam.Name}
			/>
			<ExamInfo exam={exam} selectedClass={selectedClass} />
		</CommonModal>
	);
}

function CreateNewSection({
	selectedClass,
	showCreateNew,
	setShowCreateNew,
	selectedExam,
	setSelectedExam,
}: {
	selectedClass: t.Class | undefined;
	showCreateNew: boolean;
	setShowCreateNew: any;
	selectedExam: t.Exam | undefined;
	setSelectedExam: React.Dispatch<React.SetStateAction<t.Exam | undefined>>;
}) {
	const { t } = useTranslation("Overview");

	const title = t("createNewExam");

	if (!selectedClass) return null;
	return (
		<div>
			<br />
			{showCreateNew ? (
				<>
					<div className="flexRow">
						<h2>{title}</h2>
						<h2 className="close" onClick={() => setShowCreateNew(false)}>
							&times;
						</h2>
					</div>
					<CreateNewExam
						selectedClass={selectedClass}
						selectedExam={selectedExam}
						setSelectedExam={setSelectedExam}
					/>
				</>
			) : (
				<button className="buttonRight" onClick={() => setShowCreateNew(true)}>
					{title}
				</button>
			)}
		</div>
	);
}
