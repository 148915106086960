import axios from "axios";
import * as t from "../../data/types";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function fetchAndSetTeacher(
	email: string | null,
	setTeacher: React.Dispatch<React.SetStateAction<t.Teacher | null>>
) {
	if (!email) return null;
	console.log("API CALL: fetchAndSetTeacherAPI");

	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		const res = await axios.get(
			`https://auris-db-backend.vercel.app/getTeacherByEmail?email=${email.toLocaleLowerCase().trim()}`,
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
		localStorage.setItem("teacher", JSON.stringify(res.data));
		setTeacher(res.data);
		return res.data;
	} catch (error: any) {
		Sentry.captureException(error);
		throw error;
	}
}
