import axios from "axios";
// import * as t from "../../data/types";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function addSchoolAPI(schoolName: string, schoolCountry: string, schoolEmailDomain: string, coupon?: string) {
	console.log("addClassAPI called");

	try {
		const schoolData = {
			SchoolName: schoolName,
			SchoolCountry: schoolCountry,
			EmailDomain: schoolEmailDomain,
			Coupon: coupon || "",
		};
		const idToken = await FirebaseManager.getAuthIdToken();
		const res = await axios.post("https://auris-db-backend.vercel.app/addSchool", schoolData, {
			headers: {
				Authorization: idToken,
			},
		});
		return res;
	} catch (error: any) {
		Sentry.captureException(error);
		throw error;
	}
}
