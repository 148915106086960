import axios from "axios";
import * as t from "../../data/types";
import { FirebaseManager } from "../firebase/FirebaseManager";
import * as Sentry from "@sentry/react";

export default async function createNewExamAPI(examData: t.Exam) {
	console.log("createNewExamAPI called");

	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await axios.post("https://auris-db-backend.vercel.app/createNewExam", examData, {
			headers: {
				Authorization: idToken,
			},
		});
	} catch (error: any) {
		Sentry.captureException(error);
		throw error;
	}
}
